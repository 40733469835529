import { makeStyles } from '@mui/styles';
import TopBG from '../../assets/images/core/option_bg.png'

export const optstyle = (platform) => {
    let s = makeStyles(theme => ({
        tf: {
            fontSize: '10px',
            backgroundColor: '#FFF',
            borderRadius: '6px',
            '& label.Mui-focused': {
                color: platform==='biz'?theme.palette.primary.business:theme.palette.primary.personal,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: platform==='biz'?theme.palette.primary.business:theme.palette.primary.personal,
                },
            }
        },
        border: {
            '& .MuiOutlinedInput-root': {
                border: '0px'
            }
        },
        button: {
            // background: platform==='psl'?'linear-gradient(90deg, rgba(0,112,239,1) 0%, rgba(17,120,60,1) 74%)':'linear-gradient(90deg, rgba(17,120,60,1) 20%, rgba(0,112,239,1) 100%)', 
            backgroundColor: platform==='psl'?'#0070EF':'#107038', 
            borderRadius: '6px',
            fontSize: '14px',
            shadow: 'none',
            fontWeight: 400,
            boxShadow: 'none',
            color: '#fff',
            '&:hover': {
                backgroundColor: platform==='psl'?'#0c59b0':'#0d4a27',
                // backgroundColor : '#52616B' ,
            }
        },
        fb_button: {
            backgroundColor: '#167EE7',
            fontSize: '15px',
            shadow: 'none',
            boxShadow: 'none',
            borderRadius: '12px',
            '&:hover': {
                backgroundColor: '#0c59b0',
            }
        },
        google_button: {
            backgroundColor: '#DB4437',
            fontSize: '15px',
            shadow: 'none',
            boxShadow: 'none',
            borderRadius: '12px',
            '&:hover': {
                backgroundColor: '#a3281d',
            }
        },
        plain_google_button: {
            backgroundColor: '#FFF',
            fontSize: '15px',
            shadow: 'none',
            boxShadow: '0 0.1rem 0.3rem rgba(0, 0, 0, .15)',
            borderRadius: '6px',
            '&:hover': {
                backgroundColor: '#E8ECEE',
            }
        },
        top_bg: {
            backgroundPosition: 'top', 
            backgroundSize: '100%',
            backgroundColor: '#ffffff',
            backgroundImage: `url(${TopBG})`,
            backgroundRepeat: 'no-repeat',
            // width: '47%',
            // height: '500px'
        },
    }))

    return s()
}

export const LogoAnimator = () => {
    const loaderContainer = {
        start: {
            transition: {
                staggerChildren: 0.2,
            },
        },
        end: {
            transition: {
                staggerChildren: 0.2,
            },
        },
    }
    
    const cVariants = {
        start: {
            y: '0%',
            rotate: '-9deg'
            // opacity: 0,
            // scale: 1
        },
        end: {
            y: [`-12%`, '12%'],
            rotate: '9deg'
            // opacity: 1,
            // scale: 1.5,
        },
    }

    const cVariants1 = {
        start: {
            y: '0%',
            rotate: '-15deg'
            // opacity: 0,
            // scale: 1
        },
        end: {
            y: [`-12%`, '12%'],
            rotate: '-9deg'
            // opacity: 1,
            // scale: 1.5,
        },
    }

    const cVariants2 = {
        start: {
            y: '0%',
            rotate: '15deg'
            // opacity: 0,
            // scale: 1
        },
        end: {
            y: [`-12%`, '12%'],
            rotate: '9deg'
            // opacity: 1,
            // scale: 1.5,
        },
    }
    
    const cTransition = {
        duration: 1.5,
        repeat: Infinity,
        repeatType: 'reverse',
        ease: 'easeInOut',
    }

    return {loaderContainer, cVariants, cVariants1, cVariants2, cTransition}
}