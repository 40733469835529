import { useEffect, useState } from "react";
import { Alert, Box, Button, Snackbar } from "@mui/material";
import { Link, useHistory } from 'react-router-dom';
import { env } from "../../core/Env";
import { rqx } from "../../core/request/API";
import { Cable as ICable } from "@mui/icons-material";
import { SnakeLoader } from '../../core/loader/SnakeLoader';
import OTP from 'react-otp-input';
import LPSL from '../../assets/images/logos/psl_logo.png'
import LBIZ from '../../assets/images/logos/biz_logo.png'
import IPslAccount from '../../assets/images/icons/psl_account.png'
import IBizEmail from '../../assets/images/icons/biz_email.png'

export const Verification = ({ classes, platform, va, inputs, otp, q, __SESSION }) => {
    const [resend, setResend] = useState(360)
    const [resendStat, setResendStat] = useState({value: 0, msg: 'Resend code'})
    const [verify, setVerify] = useState({step: 1, stat: false})
    const history = useHistory()
    let time = 360

    useEffect(() => {
        if (resendStat.value === 0) {
            setResendStat({...resendStat, value: 1, msg: ''})
            cd()
        }

        let otpkey = document.getElementsByClassName('otp-inputs')
        for (var i=0; i < otpkey.length; i++) {
            otpkey[i].onkeypress = function(e){
                if (e.key === 'Enter') {
                    verifyHandler()
                }
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otp.data]);

    const cd = () => {
        let countdown = setInterval(() => {
            time--
            setResend(time)
            if (time <= 0) {
                setResendStat({...resendStat, value: 2, msg: 'Resend code'})
                clearInterval(countdown)
            }
        }, 1000);
    }

    const verifyHandler = () => {
        setVerify({step: 10, stat: false})
        setTimeout(() => {
            if (otp.data.code === otp.data.inputted) {
                otp.set({...otp.data, stat: false})
                setVerify({step: 10, stat: true})
                setTimeout(() => {
                    let qsp = {
                        va: va.data,
                        account: window.btoa(`unauth_${inputs.data.account.value}`)
                    }
                    history.push(`/${platform}/registration/finalize?${q.get('from')!==null?`from=${encodeURIComponent(q.get('from'))}&`:''}${q.get('ref_a_frnd')!==null?`ref_a_frnd=${encodeURIComponent(q.get('ref_a_frnd'))}&`:''}sainfo=${encodeURIComponent(JSON.stringify(qsp))}`)
                }, 1500);
            } else {
                otp.set({...otp.data, stat: true, msg: 'Verification code is incorrect'})
                setVerify({step: 1, stat: false})
                setTimeout(() => {
                    otp.set({...otp.data, stat: false, msg: 'Re-enter verification code'})
                }, 2000);
            }
        }, 1000);
    }

    const resendCodeHandler = async () => {
        setResendStat({...resendStat, msg: 'Sending. . .'})
        let attempt = await rqx.post(`<<tool>>/${env()}/${platform}/su/attempt/${va.data}`, {account: inputs.data.account.value, ref: '1'})
        if (attempt.msg === 'delivered') {
            time = 360
            setResend(360)
            otp.set({inputted: '', code: attempt.vc, stat: false})
            setResendStat({...resendStat, value: 1, msg: ''})
            cd()
        } else if (attempt.msg === 'exists') {

        }
    }
    
    return (
        <Box height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" bgcolor={platform==='psl'?'#F2F7FE':'#F5FAF7'}>
            <Box display="flex" alignItems="center" mb={2}>
                {
                    q.get('from') !== null && (
                        <Box display="flex" alignItems="center"><Box className="personal-logo-px-2" sx={{backgroundImage: `url(${JSON.parse(JSON.parse(q.get('from')).tinfo[0].logo)})`}} /> <ICable /></Box>
                    )
                }
                <Box className="personal-logo-px-2" sx={{backgroundImage: `url(${platform==='psl'?LPSL:LBIZ})`}} />
                <Box fontSize={17} ml={1.5}>POF<b>{platform==='psl'?'Personal':'Business'}</b></Box>
            </Box>
            <Box fontSize={{xs:16, sm:32}} textAlign="center" mb={5}><b>A verification code has been sent to <br></br> your {va.data==='email'?'email account':'mobile number'}.</b></Box>
            <Box display="flex" mb={5} color={platform==='psl'?'primary.personal':'primary.business'}>
                <Box pr={1}><img src={platform==='psl'?IPslAccount:IBizEmail} alt="IPslAccount IBizEmail" /></Box>
                <Box fontSize={16} textAlign="center">{va==='mobile_num'?'+63 ': ''} <b>{inputs.data.account.value}</b></Box>
            </Box>
            <Box mb={9}>
                <Box fontSize={14} textAlign="center" pb={1} color={otp.data.stat?'error.main':'#707070'}>{otp.data.msg}</Box>
                <OTP
                    inputStyle={`otp-inputs ${otp.data.stat?'bg-otp-mismatched':platform==='psl'?'bgpsl':'bgbiz'} otp-sm-inputs`}
                    value={otp.data.inputted}
                    onChange={(e)=>otp.set({...otp.data, stat: false, inputted: e})}
                    isInputNum={true}
                    numInputs={6}
                    // separator={<span>-</span>}
                />
                <Box pt={3} px={4}>
                    <Button variant="contained" fullWidth className={`${classes.button} ${classes.bgpalette}`} onClick={verifyHandler}>
                        {
                            verify.step === 1 ? 'Verify' : <Box display="flex" alignItems="center" p={0.9}> <SnakeLoader bg="#ffffff" size="0.75rem" distance="0.3rem" /> </Box>
                        }
                    </Button>
                </Box>
            </Box>
            <Box display={{xs:'block', sm:'flex'}} textAlign="center" mb={3} fontSize={12}>
                <Box pr={0.5}>Didn't receive the verification code?</Box>
                {
                    resendStat.value!==2 ? (
                        <Box color={platform==='psl'?'primary.personal':'primary.business'}>Resend code after {resend} sec.</Box>
                    ) : (
                        <Box color={platform==='psl'?'primary.personal':'primary.business'} onClick={resendCodeHandler} className="c-pointer">{resendStat.msg}</Box>
                    )
                }
            </Box>
            {
                q.get('parent_id')===null && (
                    <Box fontSize={{xs:12, sm:14}}>Already have an account? <Link to={`/${platform}/login${q.get('from')!==null?`?from=${encodeURIComponent(__SESSION)}`:''}`} style={{color: platform==='biz'?'#11783C':'#0070EF'}}>Login</Link></Box>
                )
            }
            <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={verify.stat} 
                // autoHideDuration={6000} 
                // onClose={handleClose}
            >
                <Alert variant="filled" severity="success">Code has been verified</Alert>
            </Snackbar>
        </Box>
    )
}