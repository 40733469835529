import { Box } from "@mui/material";

const Function = ({data, index}) => {
    return (
        <Box>
            {data[index].map((item, key) =>(
                <Box className="Context" key={key}>
                    {item.data}
                </Box>
            ))}
        </Box>
    );
};
export default Function;