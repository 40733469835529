import { Box } from "@mui/system";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { RemoveRedEyeOutlined as IRemoveRedEye , VisibilityOffOutlined as IVisibilityOff } from "@mui/icons-material";
import { Link } from 'react-router-dom';
import { SnakeLoader } from "../../core/loader/SnakeLoader";
import IPslAccount from '../../assets/images/icons/psl_account.png'
import IBizEmail from '../../assets/images/icons/biz_email.png'
import IPslLock from '../../assets/images/icons/lock1.png'
import IBizLock from '../../assets/images/icons/lock2.png'

const Auth = ({ classes, platform, exist, sainfo, inputs, step, btn, validated, inputHandler, enterHandler, submitHandler, visibilityHandler, q, loginHandler }) => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={3} borderRadius={5} bgcolor="secondary.light" className={`shadow`} width={{xs:'290px', sm:'458px'}} height={{xs:'560px', sm:'592px'}}>
                <Box><img src={platform==='psl'?IPslLock:IBizLock} alt="ILock" /></Box>
                <Box fontSize={{xs:20, sm:24}}><b>Set up password</b></Box>
                <Box width="100%" mt={3}>
                    <Box display="flex" justifyContent="center" mb={3}>
                        <Box pr={1}><img src={platform==='psl'?IPslAccount:IBizEmail} alt="IPslUser" /></Box>
                        <Box fontSize={{xs:14, sm:16}} textAlign="center" color={platform==='psl'?'primary.personal':'primary.business'}>{sainfo.va==='mobile_num'?'+63 ': ''} <b>{window.atob(sainfo.account).split('_').pop()}</b></Box>
                    </Box>
                    {
                        !exist ? (
                            <Box>
                                <TextField label="Password" variant="outlined" type={inputs.password.toggle ? 'text': 'password'} name="password" value={inputs.password.value} onInput={(e)=>inputHandler(e)} error={inputs.password.stat} fullWidth className={`${classes.tf} ${classes.border}`} onKeyPress={enterHandler}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={visibilityHandler}>
                                                    {inputs.password.toggle ? <IRemoveRedEye /> : <IVisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                
                                <Box mt={2.5}>
                                    <Box display="flex" justifyContent="space-between">
                                        <Box fontSize={{xs:12, sm:14}} color="#9B9B9B">Password strength:</Box>
                                        {
                                            inputs.password.strength===5 || inputs.password.value==='' ? (
                                                <Box p={0.6} borderRadius={20}/>
                                            ) : (inputs.password.strength>=3 && inputs.password.strength<=4) ? (
                                                <Box fontSize={14} color="error.main">Poor</Box>
                                            ) : (inputs.password.strength>=1 && inputs.password.strength<=2) ? (
                                                <Box fontSize={14} color="warning.main">Average</Box>
                                            ) : (
                                                <Box fontSize={14} color="success.main">Strong</Box>
                                            )
                                        }
                                    </Box>
                                    <Box width="100%" bgcolor="#e9ebee" borderRadius={20} mt={0.5}>
                                        {
                                            inputs.password.strength===5 || inputs.password.value==='' ? (
                                                <Box p={0.6} borderRadius={20}/>
                                            ) : (inputs.password.strength>=3 && inputs.password.strength<=4) ? (
                                                <Box p={0.6} borderRadius={20} bgcolor="error.main" width="35%" />
                                            ) : (inputs.password.strength>=1 && inputs.password.strength<=2) ? (
                                                <Box p={0.6} borderRadius={20} bgcolor="warning.main" width="65%" />
                                            ) : (
                                                <Box p={0.6} borderRadius={20} bgcolor="success.main" width="100%" />
                                            )
                                        }
                                    </Box>
                                    <Box mt={1.5} color="#F44237">
                                        <Box fontSize={{xs:10, sm:12}} hidden={validated.length}>Minimum of 8 characters</Box>
                                        <Box fontSize={{xs:10, sm:12}} hidden={validated.lc}>Lower case</Box>
                                        <Box fontSize={{xs:10, sm:12}} hidden={validated.uc}>Upper case</Box>
                                        <Box fontSize={{xs:10, sm:12}} hidden={validated.number}>Number (123)</Box>
                                        <Box fontSize={{xs:10, sm:12}} hidden={validated.sc}>Symbol (eg. @*#-)</Box>
                                    </Box>
                                </Box>
                            </Box>
                        ) : (
                            <Box textAlign="center">
                                <Box>This account has already been verified.</Box>
                            </Box>
                        )
                    }
                </Box>
                <Box mt="auto" width="100%">
                    {
                        !exist ? (
                            <Box>
                                <Box fontSize={{xs:12, sm:14}} pt={1} textAlign="center">
                                By clicking submit, you agree with our <Link pl={0.5} style={{ color: platform==='psl'? '#0070ef' : '#11783C', textDecoration: 'underline' }} to="/termsandconditions" target="_blank">Terms and conditions</Link>, <Link pl={0.5} style={{ color: platform==='psl'? '#0070ef' : '#11783C', textDecoration: 'underline'  }} to="/dataprivacypolicy" target="_blank">Data privacy policy</Link> and <Link pl={0.5} style={{ color: platform==='psl'? '#0070ef' : '#11783C', textDecoration: 'underline'  }} to="/cookiepolicy" target="_blank">Cookie policy</Link>
                                </Box>
                                <Box pt={3}>
                                    <Button variant="contained" fullWidth className={`${classes.button} ${classes.bgpalette}`} onClick={submitHandler} disabled={inputs.password.strength!==0?true:false}>
                                        {
                                            step===1?(
                                                <Box display="flex" alignItems="center">Submit</Box>
                                            ) : (
                                                btn==='Submit'?(
                                                    <Box display="flex" alignItems="center" p={0.9}> <SnakeLoader bg="#ffffff" size="0.75rem" distance="0.3rem" /> </Box>
                                                ) : (
                                                    <Box display="flex" alignItems="center">Submit</Box>
                                                )
                                            )
                                        }
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <Button variant="contained" fullWidth className={`${classes.button} ${classes.bgpalette}`} onClick={loginHandler}>Go to login</Button>
                        )
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default Auth;