import { Container, Divider, Stack, Button } from "@mui/material"
import { Box } from "@mui/system"
import { Navigation } from "./Navigation"
import { makeStyles } from '@mui/styles';
import { VM } from "../../core/VM";
import { useLocation } from "react-router-dom";
// import { ToolContext } from "../../core/context/ToolContext";
// import { useContext, useEffect } from "react";
//import { ArrowRightAltTwoTone as IArrowRightAlt } from "@mui/icons-material";
import { domain } from "../../core/Env";


const style = makeStyles(theme => ({
    button: {
        borderRadius: '8px'    
    },
    buttonws: { 
        borderRadius: '8px',
        minWidth: '200px',
        
    },
    vidFrame: {
        borderRadius: '8px',
        width: '100%',
        height: '200px',
        [theme.breakpoints.up('sm')]: {
            width: '560px',
            height: '315px',
        }
    }
}));

export const Home = () => {
    const q = new URLSearchParams(useLocation().search);
    const classes = style();

    return (
        <Box height="100%">
            <Container sx={{height: '100%'}}>
                <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" height="100%">
                    <Navigation q={q} />
                    <Box textAlign="center">
                        <Box mb={1} fontSize={VM()==='/devices/mobile'?20:40}>Grow your <Box component="b" color="primary.personal">Career</Box> & <Box component="b" color="primary.business">Business</Box></Box>
                        <Box fontSize={VM()==='/devices/mobile'?16:24} fontWeight="900">10x Faster 10x Higher 10x Bigger</Box>
                        <Box display="flex" justifyContent="center" mt={1} mb={1}>
                            {/* <Divider sx={{width: '50px', border: '2px solid black'}}></Divider> */}
                        </Box>
                        <Box display="flex" justifyContent="center" p={1.5} bgcolor="#fff" borderRadius={4}>
                            <iframe src="https://www.youtube.com/embed/JFVrXP1yz7M?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className={classes.vidFrame} ></iframe>
                        </Box>
                        <Box>
                          <Stack direction="row" spacing={2} justifyContent='center' py='15px'>
                            <a href={`${domain('psl').ws}`}><Button variant="contained" className={`${classes.buttonws}`} style={{backgroundColor: '#0070EF'}} > 
                            <Box py='4px'>
                                <Box fontSize={14} lineHeight="16px">PERSONAL</Box> 
                                <Box fontSize={12} lineHeight="14px">Yes, I want to Grow my Career </Box>
                                <Box fontSize={10}>10x Faster 10x Higher 10x Bigger</Box>
                            </Box>
                            </Button></a>
                            <a href={`${domain('biz').ws}`}><Button variant="contained" className={`${classes.buttonws}`} style={{backgroundColor: '#11783C'}} >
                            <Box py='4px'>
                                <Box fontSize={14} lineHeight="16px">BUSINESS </Box> 
                                <Box fontSize={12} lineHeight="14px">Yes, I want to Grow my Business </Box>
                                <Box fontSize={10}>10x Faster 10x Higher 10x Bigger</Box>
                            </Box>
                            </Button></a>
                            </Stack>
                        </Box>
                        {/* <Box fontSize={24} mt={3} mb={2}><b>JOIN & GROW WITH US!</b></Box> */}
                    </Box>
                    <Box fontSize={14} mb={3}>pofsis © 2022 </Box>
                </Box>
            </Container>
        </Box>
    )
}