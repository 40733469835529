import { makeStyles } from '@mui/styles';

export const pslstyle = (platform) => {
    let s = makeStyles(theme => ({
        tf: {
            fontSize: '10px',
            backgroundColor: '#FFF',
            '& label.Mui-focused': {
                color: platform==='biz'?theme.palette.primary.business:theme.palette.primary.personal,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: platform==='biz'?theme.palette.primary.business:theme.palette.primary.personal,
                },
            }
        },
        border: {
            '& .MuiOutlinedInput-root': {
                borderRadius: '10px'
            }
        },
        button: {
            backgroundColor: platform==='psl'?'#0070EF':'#11783C', 
            borderRadius: '8px',
            fontSize: '15px',
            shadow: 'none',
            boxShadow: 'none',
            color: '#fff',
            '&:hover': {
                backgroundColor: platform==='psl'?'#0c59b0':'#0d4a27',
            }
        },
        fb_button: {
            backgroundColor: '#167EE7',
            fontSize: '15px',
            shadow: 'none',
            boxShadow: 'none',
            borderRadius: '12px',
            '&:hover': {
                backgroundColor: '#0c59b0',
            }
        },
        google_button: {
            backgroundColor: '#DB4437',
            fontSize: '15px',
            shadow: 'none',
            boxShadow: 'none',
            borderRadius: '12px',
            '&:hover': {
                backgroundColor: '#a3281d',
            }
        }
    }))

    return s()
}