import { Button, Stack } from "@mui/material"
import { Box } from "@mui/system"
import { makeStyles } from '@mui/styles';
import { ArrowRightAltTwoTone as IArrowRightAlt } from "@mui/icons-material";
import IMGPofsis from '../../assets/images/logos/pofsis_no_border.png'
// import { Link } from 'react-router-dom';
import { VM } from "../../core/VM";
import { domain } from "../../core/Env";

const style = makeStyles({
    button: {
        borderRadius: '8px'    
    }
});

export const Navigation = ({ q }) => {
    const classes = style();
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" py={3} width="100%">
            <Box display="flex" alignItems="center">
                <img src={IMGPofsis} alt="POFSIS LOGO NO BORDER" width="30"/>
                {VM()==='/devices/mobile'?'':<Box fontSize={24} ml={1}><b>POF</b>SIS</Box>}
            </Box>
            <Stack direction="row" spacing={2}>
                <a href={`${domain('psl').url}`}><Button variant="contained" className={`${classes.button}`} style={{backgroundColor: '#0070EF'}}>Personal Login <IArrowRightAlt style={{marginLeft: 5}} /></Button></a>
                <a href={`${domain('biz').url}`}><Button variant="contained" className={`${classes.button}`} style={{backgroundColor: '#11783C'}}>Business Login<IArrowRightAlt style={{marginLeft: 5}} /></Button></a>
            </Stack>
        </Box>
    )
}