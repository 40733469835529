import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { context } from  './home/Context';
import Logo from '../../assets/images/terms/logo.png';
import Terms from './home/Function';

export const DataPolicy = () => {
	const useStyles = makeStyles({
		root: {
			color: '#A9A9A9',
			'&:hover, &:focus': {
				color: '#0070ef',
				backgroundColor: 'white',
			}
		},
		active: {
			color: '#0070ef',
			backgroundColor: 'white',
		},
		text: {
			fontFamily: 'Inter',
			fontSize: '17',
			mr: '5'
		},
		box: {
			backgroundColor: 'white',
			color: "black",
		}
	});
	const classes = useStyles();

	return ( 
		<Box>
			<Box className={classes.box} sx={{ pl: {xs:0, sm:12}, pt: 4, pb: 3, mt: 0.50, margin: {xs:0, lg:'auto'}, mb: 0.3, width:{xs:'100%', lg:'80%'} }}>
				<Typography sx={{ fontFamily: 'Inter', textAlign:{xs:'center', sm:'left'} }} variant="h5"><img src={Logo} alt="logo" width="44"/><b>POF</b>SIS</Typography><br/>
				<Box display={{xs:'flex', sm:'block'}} justifyContent="center" alignItems="center">
					<Link to="/termsandconditions">
						<Button className={classes.root}>    
							<Typography className={classes.text} sx={{ mr:{xs:0, sm:5}, fontSize:{xs:10, sm:14} }}>Terms & Conditions</Typography>
						</Button >
					</Link>
					<Link to="/dataprivacypolicy">
						<Button className={`${classes.root} ${classes.active}`}>    
							<Typography className={classes.text} sx={{ mr:{xs:0, sm:5}, fontSize:{xs:10, sm:14} }}>Data Privacy Policy</Typography>
						</Button >
					</Link>
					<Link to="/cookiepolicy">
						<Button className={classes.root}>    
							<Typography className={classes.text} sx={{ mr:{xs:0, sm:5}, fontSize:{xs:10, sm:14} }}>Cookies Policy</Typography>
						</Button>
					</Link>
				</Box>
			</Box>
			<Box className={classes.box} sx={{ px: {xs:3, sm:12}, pt: 6, pb: 4, margin: "auto"}}>
				<Terms data={context()} index={1}/>
			</Box>
		</Box>
	);
}

export default DataPolicy;