// import { lazy, Suspense, useContext, useEffect } from "react"
// import { VM } from "../../core/VM"
// import { Box } from "@mui/material";
// // import { Verification } from "./Verification";
// const device = VM();
// const Auth = lazy(() => import(`.${device}/Auth`))
// const __SESSION = JSON.parse(localStorage.getItem('pm_tool_session'))

// export const UseBeforeRegistrationSU = ({ match }) => {
//     console.log('ubrsu')
    
//     return (
//         <Suspense fallback="">
//             {/* {
//                 (step.data === 1 || step.data === 10) ? (
//                     <Auth classes={classes} platform={platform} {...su_state} inputHandler={inputHandler} enterHandler={enterHandler} next={next} q={q} __SESSION={__SESSION} />
//                 ) : (
//                     <Verification classes={classes} platform={platform} {...su_state} q={q} __SESSION={__SESSION} />
//                 )
//             } */}
//         </Suspense>
//     )
// }

import { lazy, Suspense, useContext, useEffect } from "react"
import { VM } from "../../core/VM"
import { pslstyle } from "../../core/styling/si"
import { ToolContext } from "../../core/context/ToolContext";
import { validateAccount } from "../../core/validator/Validator";
import { rqx } from '../../core/request/API';
import { env } from '../../core/Env';
import { useLocation, useHistory } from 'react-router-dom';
import { Verification } from "./Verification";

const device = VM();
const Auth = lazy(() => import(`.${device}/Auth`))
const __SESSION = JSON.parse(localStorage.getItem('pm_tool_session'))

export const UseBeforeRegistrationSU = ({ match }) => {
    const { su_state } = useContext(ToolContext)
    const { step, va, inputs, tpinputs, otp } = su_state
    const q = new URLSearchParams(useLocation().search);
    const platform = match.params.platform
    const entry = match.params.entry
    const classes = pslstyle(platform)
    const history = useHistory()

    const inputHandler = (e) => {
        const {name, value} = e.target
        tpinputs.set({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})
        inputs.set({...inputs.data, [name] : {...inputs.data[name], value: value, stat: false, msg: ''}})
        if (name==='account') {
            const v = validateAccount(e.target.value);
            if (v==='mobile_num' && e.target.value[0]==='0') {
                inputs.set({...inputs.data, [name] : {...inputs.data[name], value: e.target.value.substring(1), stat: false, msg: ''}})
            }
            va.set(v)
        }
    }

    const enterHandler = (event) => { return event.key === 'Enter' ? next() : ''}
    const next = async () => {
        if (step.data!==10 && step.data!==20) {
            tpinputs.set({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})
            if (step.data===1) {
                step.set(10)
                if (inputs.data.account.value !== '') {
                    if (va.data!=='username') {
                        let attempt = await rqx.post(`<<tool>>/${env()}/${platform}/su/attempt/${va.data}`, {account: inputs.data.account.value, ref: '1'})
                        // console.log(attempt);
                        if (attempt.msg === 'delivered') {
                            // redirect 
                            otp.set({inputted: '', code: attempt.vc, msg: 'Enter verification code'})
                            step.set(2)
                            inputs.set({...inputs.data, account: {...inputs.data.account, stat: false, msg: ''}})
                        } else if (attempt.msg === 'exists') {
                            if (q.get('parent_id') === null) {  
                                if (platform==='psl') {
                                    if (attempt.ut === 2) {
                                        step.set(1)
                                        inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Company account can't be used to POFPersonal"}})
                                        return
                                    }
                                    if (attempt.ut === 5) {
                                        step.set(1)
                                        inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Clients are unable to use their accounts in this particular system."}})
                                        return
                                    }
                                    if (attempt.at!==2) {
                                        step.set(1)
                                        inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account already exist."}})
                                    } else {
                                       let qsp = {
                                            va: va.data,
                                            account: window.btoa(`unauth_${inputs.data.account.value}`),
                                            step: 1,
                                            srf: 'default'
                                        }
                                        step.set(1)
                                        history.push(`/biz/switching/option1?${q.get('from')!==null?`from=${encodeURIComponent(q.get('from'))}&`:''}sainfo=${encodeURIComponent(JSON.stringify(qsp))}`)
                                    }
                                } else {
                                    if (attempt.ut === 5) {
                                        step.set(1)
                                        inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Clients are unable to use their accounts in this particular system."}})
                                        return
                                    }
                                    if (attempt.at!==1) {
                                        step.set(1)
                                        inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account already exist."}})
                                    } else {
                                       let qsp = {
                                            va: va.data,
                                            account: window.btoa(`unauth_${inputs.data.account.value}`),
                                            step: 1,
                                            srf: 'default'
                                        }
                                        step.set(1)
                                        history.push(`/psl/switching/option1?${q.get('from')!==null?`from=${encodeURIComponent(q.get('from'))}&`:''}sainfo=${encodeURIComponent(JSON.stringify(qsp))}`)
                                    }
                                }
                            } else {
                                step.set(1)
                                inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account already exist."}})
                            }
                        }
                    } else {
                        step.set(1)
                        inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account format is invalid."}})
                    }
                } else {
                    step.set(1)
                    inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account is required"}})
                }
            }
        }
    }

    useEffect(() => {
        // let data = {
        //     pcred: 'ads1234567891@gmail.com',
        //     first_name: 'test1',
        //     last_name: 'testls2',
        //     suffix: '',
        //     middle_name: '',
        //     contact_info: {},
        //     other_info: { birthdate: null, gender: null, civil_status: null, },
        //     profile:{
        //         profile: null,
        //         cover: null
        //     }
        // }
        // console.log(`localhost:3000/sso/rqx?from=PCHT_SSO_RQX&callback=${env()==='dev'?'development':env()==='sb'?'sandbox':env()}&platform=personal&entry=ubr_profile&auth_type=registration&subcb=${window.btoa(JSON.stringify(data))}`)
        const __init = async() => {
            if (entry === 'ubr_profile') {
                try {
                    if (JSON.parse(window.atob(JSON.parse(q.get('from')).subcb))) {
                        let data = JSON.parse(window.atob(JSON.parse(q.get('from')).subcb))
                        // const {name, value} = e.target
                        tpinputs.set({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})
                        inputs.set({...inputs.data, ['account'] : {...inputs.data['account'], value: data.pcred, stat: false, msg: ''}})
                        const v = validateAccount(data.pcred);
                        const vala = validateAccount(data.pcred);
                        if (v==='mobile_num' && data.pcred[0]==='0') {
                            inputs.set({...inputs.data, ['account'] : {...inputs.data['account'], value: data.pcred.substring(1), stat: false, msg: ''}})
                        }
                        va.set(v)
                        if (vala === 'email' || vala === 'mobile_num') {
                            if (data.first_name && data.last_name) {
                                if (step.data!==10 && step.data!==20) {
                                    tpinputs.set({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})
                                    if (step.data===1) {
                                        step.set(10)
                                        if (data.pcred !== '') {
                                            if (vala!=='username') {
                                                let attempt = await rqx.post(`<<tool>>/${env()}/${platform}/su/attempt/${vala}`, {account: data.pcred, ref: '1'})
                                                if (attempt.msg === 'delivered') {
                                                    // redirect 
                                                    otp.set({inputted: '', code: attempt.vc, msg: 'Enter verification code'})
                                                    step.set(2)
                                                    inputs.set({...inputs.data, account: {...inputs.data.account, stat: false, msg: '', value: data.pcred}})
                                                } else if (attempt.msg === 'exists') {
                                                    if (q.get('parent_id') === null) {  
                                                        if (platform==='psl') {
                                                            if (attempt.ut === 2) {
                                                                step.set(1)
                                                                inputs.set({...inputs.data, account: {...inputs.data.account, value: data.pcred, stat: true, msg: "Company account can't be used to POFPersonal"}})
                                                                return
                                                            }
                                                            if (attempt.ut === 5) {
                                                                step.set(1)
                                                                inputs.set({...inputs.data, account: {...inputs.data.account, value: data.pcred, stat: true, msg: "Clients are unable to use their accounts in this particular system."}})
                                                                return
                                                            }
                                                            if (attempt.at!==2) {
                                                                step.set(1)
                                                                inputs.set({...inputs.data, account: {...inputs.data.account, value: data.pcred, stat: true, msg: "Account already exist."}})
                                                            } else {
                                                               let qsp = {
                                                                    va: vala,
                                                                    account: window.btoa(`unauth_${data.pcred}`),
                                                                    step: 1,
                                                                    srf: 'default'
                                                                }
                                                                step.set(1)
                                                                history.push(`/biz/switching/option1?${q.get('from')!==null?`from=${encodeURIComponent(q.get('from'))}&`:''}sainfo=${encodeURIComponent(JSON.stringify(qsp))}`)
                                                            }
                                                        } else {
                                                            if (attempt.ut === 5) {
                                                                step.set(1)
                                                                inputs.set({...inputs.data, account: {...inputs.data.account, value: data.pcred, stat: true, msg: "Clients are unable to use their accounts in this particular system."}})
                                                                return
                                                            }
                                                            if (attempt.at!==1) {
                                                                step.set(1)
                                                                inputs.set({...inputs.data, account: {...inputs.data.account, value: data.pcred, stat: true, msg: "Account already exist."}})
                                                            } else {
                                                               let qsp = {
                                                                    va: va.data,
                                                                    account: window.btoa(`unauth_${data.pcred}`),
                                                                    step: 1,
                                                                    srf: 'default'
                                                                }
                                                                step.set(1)
                                                                history.push(`/psl/switching/option1?${q.get('from')!==null?`from=${encodeURIComponent(q.get('from'))}&`:''}sainfo=${encodeURIComponent(JSON.stringify(qsp))}`)
                                                            }
                                                        }
                                                    } else {
                                                        step.set(1)
                                                        inputs.set({...inputs.data, account: {...inputs.data.account, value: data.pcred, stat: true, msg: "Account already exist."}})
                                                    }
                                                }
                                            } else {
                                                step.set(1)
                                                inputs.set({...inputs.data, account: {...inputs.data.account, value: data.pcred, stat: true, msg: "Account format is invalid."}})
                                            }
                                        } else {
                                            step.set(1)
                                            inputs.set({...inputs.data, account: {...inputs.data.account, value: data.pcred, stat: true, msg: "Account is required"}})
                                        }
                                    }
                                }
                            } else {
                                console.log('first name and last name must have value')
                            }
                        } else {
                            console.log("wrong format account")
                        }
                    }
                } catch (error) {
                    console.log("wrong format contact dev")
                }
            }
        }
        __init()
    }, [])  
    
    

    return (
        <Suspense fallback="">
            {
                ((step.data === 1 || step.data === 10)) ? (
                    <Auth classes={classes} platform={platform} {...su_state} inputHandler={inputHandler} enterHandler={enterHandler} next={next} q={q} __SESSION={__SESSION} />
                ) : (
                    <Verification classes={classes} platform={platform} entry={entry} {...su_state} q={q} __SESSION={__SESSION} />
                )
            }
        </Suspense>
    )
}