import { useState } from "react"

export const SIStates = () => {
    const [step, setStep] = useState(1)
    const [va, setVa] = useState('')
    const [inputs, setInputs] = useState({account:{value: '', stat: false, msg: ''}, password:{value: '', stat: false, msg: '', toggle: false}})
    const [tpinputs, setTpinputs] = useState({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})

    return {
        step: {data: step, set: setStep},
        va: {data: va, set: setVa},
        inputs: {data: inputs, set: setInputs},
        tpinputs: {data: tpinputs, set: setTpinputs}
    }
}