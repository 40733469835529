import { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Box } from '@mui/material/';
import { ToolContext } from './core/context/ToolContext';
import { WebRoute } from "./core/webrouter/Route";
import { InstallPromotion } from './components/layouts/installation/InstallPromotion';
import { SnakeLoader } from './core/loader/SnakeLoader';
// import { rqx } from './core/request/API';
// import { env } from './core/Env';
// import { motion } from 'framer-motion';
import LLogo from './assets/images/logos/pofsis_no_border.png'
import { VM } from './core/VM';

const __SESSION = JSON.parse(localStorage.getItem('pm_tool_session'))

export const Core = () => {
	const { tool_state, install_state } = useContext(ToolContext)
    const q = new URLSearchParams(window.location.search);

	// const __installer = () => {
	// 	window.addEventListener('beforeinstallprompt', (e) => {
	// 		e.preventDefault();
	// 		install_state.prompt.set(e)
	// 	});
	// }

	// const installPromptHandler = () => {
	// 	setTimeout(() => {
	// 		install_state.ip.set({stat: true, prompt_type: 'first_prompt'})
	// 	}, 10000);
	// }
	
	useEffect(() => {
		// const __init = async () => {
			// RUN AN API REQUEST HERE ( SAMPLE REQUESTS BELOW: )
			// let ainfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/account/read`, {aid: __SESSION.ainfo.aid})
			// let pinfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/primeinfo/read`, {aid: __SESSION.ainfo.aid})

			// SET STATES HERE ( SAMPLE STORING BELOW: )
			// tool_state.__SESSION.set({ainfo, pinfo})
			// tool_state.prefetch.set(true)
			
			// RUN PWA INSTALLATION PROMPT HANDLER
			// installPromptHandler()
		// }
		
		// if (__SESSION !== null) {
		// 	__installer()
		// 	__init()
		// } else {
			setTimeout(() => {
				// SET STATES HERE ( SAMPLE STORING BELOW: )
				tool_state.prefetch.set(true)
				if (q.get('from') === null) {
					if (__SESSION !== null) {
						localStorage.removeItem('pm_tool_session')
						if (q.get('sainfo') === null || q.get('sainfo') === undefined) {
							window.location.href = window.location.pathname
						}
					}
				}

				console.log(`${VM()===''?'desktop':VM()} is the current device`);
				console.log(`POFMain V2.1.59 is running`);
				// RUN PWA INSTALLATION PROMPT HANDLER
				// installPromptHandler()
			}, 1000);
		// }
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Box width="100%" height="100%">
			{
				tool_state.prefetch.data ? (
					<Router>
						<Box height="100%">
							<Box flexGrow={1} height="100%">
								<WebRoute />
							</Box>
						</Box>
					</Router>
				) : (
					<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
						<img src={LLogo} width={50} alt={LLogo} />
						<Box mt={4}>
							<SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
						</Box>
					</Box>
				)
			}
			<InstallPromotion install_state={install_state} />
		</Box>
	)
}