import { lazy, Suspense, useContext, useEffect } from "react"
import { Box, Snackbar, Alert } from "@mui/material";
import { VM } from "../../../core/VM"
import { optstyle, LogoAnimator } from "../../../core/styling/options"
import { ToolContext } from "../../../core/context/ToolContext";
import { validateAccount } from "../../../core/validator/Validator";
import { rqx } from '../../../core/request/API';
import { env } from '../../../core/Env';
import { useLocation, useHistory } from 'react-router-dom';
import { SnakeLoader } from "../../../core/loader/SnakeLoader";
import LLogo from '../../../assets/images/logos/pofsis_no_border.png'
// import { Verification } from "./Verification";
const device = VM();
const Auth = lazy(() => import(`.${device}/Auth`))
const VerificationV2 = lazy(() => import(`.${device}/Verification_v2`))
const __SESSION = JSON.parse(localStorage.getItem('pm_tool_session'))

export const Option3 = ({ match }) => {
    const { switching_state } = useContext(ToolContext)
    const { stat, step, va, inputs, otp, tpinputs } = switching_state
    const q = new URLSearchParams(useLocation().search);
    const platform = match.params.platform
    const classes = optstyle(platform)
    const animator = LogoAnimator()
    const history = useHistory()

    useEffect(() => {
        const __init = () => {
            const sainfo = JSON.parse(q.get('sainfo'))
            if (sainfo !== null) {
                step.set(1)
                stat.set({linked: false, allowed: true})
                va.set(sainfo.va)
                inputs.set({...inputs.data, aid : {...inputs.data.aid, value: sainfo.aid, stat: false, msg: ''}})
                return
            }

            step.set('!sainfo')
            stat.set({linked: false, allowed: false})
        }

        __init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const backHandler = () => {
        if (step.data === 1) {
            history.goBack()
        } else if (step.data === 2) {
            step.set(1)
            inputs.set({...inputs.data, account: {...inputs.data.account, stat: false, msg: ''}})
        } else {
            history.goBack()
        }
    }
    

    const inputHandler = (e) => {
        const {name, value} = e.target
        tpinputs.set({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})
        inputs.set({...inputs.data, [name] : {...inputs.data[name], value: value, stat: false, msg: ''}})
        if (name==='account') {
            const v = validateAccount(e.target.value);
            if (v==='mobile_num' && e.target.value[0]==='0') {
                inputs.set({...inputs.data, [name] : {...inputs.data[name], value: e.target.value.substring(1), stat: false, msg: ''}})
            }
            va.set(v)
        }
    }

    const enterHandler = (event) => { return event.key === 'Enter' ? next() : ''}
    const next = async () => {
        if (step.data!==10 && step.data!==20) {
            tpinputs.set({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})
            inputs.set({password: {...inputs.data.password, stat: false, msg: ''}, account: {...inputs.data.account, stat: false, msg: ''}, aid: {...inputs.data.aid, stat: false, msg: ''}})
            if (inputs.data.account.value === '') {
                step.set(1)
                inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account is required"}})
                return
            }

            if (va.data === 'username') {
                step.set(1)
                inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account format is invalid"}})
                return
            }

            step.set(10)
            let attempt = await rqx.post(`<<tool>>/${env()}/${platform}/su/attempt/${va.data}`, {account: inputs.data.account.value, ref: 1})
            if (attempt.msg === 'delivered') {
                step.set(2)
                otp.set({inputted: '', code: attempt.vc, msg: 'Enter verification code'})
                inputs.set({...inputs.data, account: {...inputs.data.account, stat: false, msg: ''}})
            } else if (attempt.msg === 'exists') {
                step.set(1)
                inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account already exists"}})
            }
        }
    }
    
    return (
        <Suspense fallback="">
            {
                stat.data.allowed !== null ? (
                    stat.data.allowed ? (
                        (step.data === 1 || step.data === 10) ? (
                            <Auth classes={classes} platform={platform} {...switching_state} inputHandler={inputHandler} enterHandler={enterHandler} next={next} q={q} __SESSION={__SESSION} animator={animator} backHandler={backHandler}/>
                        ) : (
                            <VerificationV2 classes={classes} platform={platform} {...switching_state} q={q} __SESSION={__SESSION} backHandler={backHandler}/>
                        )
                    ) : (
                        <Box>asd</Box>
                    )
                ) : (
                    <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <img src={LLogo} width={50} alt={LLogo} />
                        <Box mt={4}>
                            <SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
                        </Box>
                    </Box>
                )
            }
            <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={stat.data.linked} 
                // autoHideDuration={6000} 
                // onClose={handleClose}
            >
                <Alert variant="filled" severity="success">Account successfully linked</Alert>
            </Snackbar>
        </Suspense>
    )
}

// let sainfo = {
//     aid: aid__ng__nka__login,
//     va: email__or__mobile_num
// }
// window.location.href = `${url__ng__pofmain}/${psl__or__biz}/switching/option3?sainfo=${encodeURIComponent(JSON.stringify(sainfo))}`