import { createContext } from 'react';
import { InstallState } from './InstallState';
import { ToolStates } from './ToolStates';
import { HomeStates } from './HomeStates';
import { SIStates } from './SIStates';
import { SUStates } from './SUStates';
import { ResetPasswordStates } from './ResetPasswordStates';
import { SwitchingStates } from './SwitchingStates';

export const ToolContext = createContext();

export const ToolProvider = ({ children }) => {
    let states = {
        install_state: InstallState(),
        tool_state: ToolStates(),
        home_state: HomeStates(),
        si_state: SIStates(),
        su_state: SUStates(),
        reset_password_state: ResetPasswordStates(),
        switching_state: SwitchingStates(),
    }

    return (
        <ToolContext.Provider value={{...states}}>
            {children}
        </ToolContext.Provider>
    )
}