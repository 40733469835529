import { Box, Snackbar, Alert } from "@mui/material"
import { useContext, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { ToolContext } from "../../core/context/ToolContext"
import { env, domain } from "../../core/Env"
import { rqx } from "../../core/request/API"
import { pslstyle } from "../../core/styling/si"
import { validatePassword } from "../../core/validator/Validator"
import { SnakeLoader } from "../../core/loader/SnakeLoader";
import LLogo from '../../assets/images/logos/pofsis_no_border.png'
import Auth from "./Auth"

// CAVERIFICATION = COMPANY ACCOUNT VERIFICATION
export const CAVerification = ({ match }) => {
    const { reset_password_state } = useContext(ToolContext)
    const { step, sainfo, inputs, exist, submitted } = reset_password_state.finalize.data
    const platform = match.params.platform
    const classes = pslstyle(platform)
    const q = new URLSearchParams(useLocation().search);
    const history = useHistory()

    useEffect(() => {
        let s = JSON.parse(q.get('sainfo'))
        // console.log(s)
        const __init = async () => {
            let check = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/account/read`, {aid: s.aid})
            if (check.verified !== 1) {
                reset_password_state.finalize.set({...reset_password_state.finalize.data, exist: false, sainfo: s})
            } else {
                reset_password_state.finalize.set({...reset_password_state.finalize.data, exist: true, sainfo: s})
            }
        }
        __init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const inputHandler = (e) => {
        const {name, value} = e.target
        let vp = validatePassword(e.target.value)
        let inps = {...inputs}
        inps[name].value = value
        inps[name].stat = false
        inps[name].msg = ''
        inps[name].strength = vp.svalidator
        reset_password_state.finalize.set({...reset_password_state.finalize.data, validated: vp.validated, inputs: inps})
    }

    const visibilityHandler = () => {
        let inps = {...inputs}
        inps.password.toggle = !inps.password.toggle
        reset_password_state.finalize.set({...reset_password_state.finalize.data, inputs: inps})
    }

    const enterHandler = (event) => { return event.key === 'Enter' ? submitHandler() : ''}
    const submitHandler = async () => {
        if (step!==10 && step!==20) {
            if (inputs.password.value !== '') {
                let vp = validatePassword(inputs.password.value)
                if (vp.wvalidator === 0) {
                    reset_password_state.finalize.set({...reset_password_state.finalize.data, step: 10})
                    let attempt = await rqx.post(`<<tool>>/${env()}/${platform}/resetpass/attempt/${sainfo.va}`, {account: window.atob(sainfo.account).split('_').pop(), password: inputs.password.value, aid: sainfo.aid, ref: '2'})
                    
                    if (attempt.msg === 'success') {
                        reset_password_state.finalize.set({...reset_password_state.finalize.data, btn: 'Redirecting', submitted: true})
                        const ainfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/account/read`, {aid: sainfo.aid})
                        
                        setTimeout(() => {
                            window.location.href = `${domain('biz').url}/sso/account/auth?ainfo=${encodeURIComponent(JSON.stringify(ainfo))}`
                        }, 1500);
                    }
                } else {
                    // setInputs({...inputs, password : {...inputs.password, stat: true, msg: 'Minimum of 6 characters.'}})
                }
            } else {
                // setInputs({...inputs, password : {...inputs.password, stat: true, msg: 'Minimum of 6 characters.'}})
            }
        }
    }

    
    const loginHandler = () => {
        history.push(`/${platform}/login`)
    }

    return exist !== null ? (
        <Box height="100%">
            <Auth platform={platform} classes={classes} {...reset_password_state.finalize.data} inputHandler={inputHandler} enterHandler={enterHandler} submitHandler={submitHandler} q={q} visibilityHandler={visibilityHandler} loginHandler={loginHandler} />
            <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={submitted} 
                    // autoHideDuration={6000} 
                    // onClose={handleClose}
                >
                <Alert variant="filled" severity="success">Account successfully registered</Alert>
            </Snackbar>
        </Box>
    ) : (
        <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <img src={LLogo} width={50} alt={LLogo} />
            <Box mt={4}>
                <SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
            </Box>
        </Box>
    )
}

// let sainfo = {
//     va: email__or__mobile_num,
//     account: window.btoa(`unauth_${email__or__mobile_num__ng__nka__login}`),
//     aid: aid__ng__nka__login
// }
// window.location.href = `${url__ng__pofmain}/${psl__or__biz}/comp/account/verification?sainfo=${encodeURIComponent(JSON.stringify(sainfo))}`