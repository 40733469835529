import { Route, Switch } from 'react-router-dom';
import { Home } from '../../components/home/Index';
import { Box, Container } from '@mui/material';
import { SI } from '../../components/si/Index';
import { SU } from '../../components/su/Index';
import { Finalize as SUFinalize} from '../../components/su/finalize/Index';
import { ResetPassword } from '../../components/resetPassword/Index';
import { Finalize as RPFinalize} from '../../components/resetPassword/finalize/Index';
import { ToolSI } from '../../components/toolSI/Index';
import { Option1 } from '../../components/switching/option1/Index';
import { Option2 } from '../../components/switching/option2/Index';
import { Option3 } from '../../components/switching/option3/Index';
import { Finalize as OPT3Finalize } from '../../components/switching/option3/finalize/Index';
import { CAVerification } from '../../components/caverification/Index';
import { UseBeforeRegistrationSU } from '../../components/ubrsu/Index';
import { Finalize as UBRFinalize } from '../../components/ubrsu/finalize/Index';
import { Home as Terms } from '../../components/policies/Terms';
import { DataPolicy } from '../../components/policies/DataPolicy';
import { CookiePolicy } from '../../components/policies/CookiePolicy';

export const WebRoute = () => {
    return (
        <Switch>
            {/* HOME ROUTES */}
            <Route exact path="/" render={props=>(<Home {...props} />)} />

            {/* SI ROUTES */}
            <Route exact path="/:platform/login" render={props=>(<SI {...props} />)} />
            
            {/* TOOL SI ROUTES */}
            <Route exact path="/:platform/tool/login" render={props=>(<ToolSI {...props} />)} />

            {/* SU ROUTES */}
            <Route exact path="/:platform/registration" render={props=>(<SU {...props} />)} />
            <Route exact path="/:platform/registration/finalize" render={props=>(<SUFinalize {...props} />)} />

            {/* RESET PASSWORD */}
            <Route exact path="/:platform/reset/password" render={props=>(<ResetPassword {...props} />)} />
            <Route exact path="/:platform/reset/password/finalize" render={props=>(<RPFinalize {...props} />)} />

            {/* SYNC / LINK  ROUTES */}
            <Route exact path="/:platform/switching/option1" render={props=>(<Option1 {...props} />)} />
            <Route exact path="/:platform/switching/option2" render={props=>(<Option2 {...props} />)} />
            <Route exact path="/:platform/switching/option3" render={props=>(<Option3 {...props} />)} />
            <Route exact path="/:platform/switching/option3/finalize" render={props=>(<OPT3Finalize {...props} />)} />

            {/* COMPANY ACCOUNT VERIFICATION */}
            <Route exact path="/:platform/comp/account/verification" render={props=>(<CAVerification {...props} />)} />

            {/* Use Before Register Route */}
            <Route exact path="/:platform/ubr/:entry/registration" render={props=>(<UseBeforeRegistrationSU {...props} />)} />
            <Route exact path="/:platform/ubr/:entry/registration/finalize" render={props=>(<UBRFinalize {...props} />)} />

            {/* CONDITIONS AND POLICIES */}
            <Route exact path="/termsandconditions" render={props=>(<Terms {...props} />)} />
            <Route exact path="/dataprivacypolicy" render={props=>(<DataPolicy {...props} />)} />
            <Route exact path="/cookiepolicy" render={props=>(<CookiePolicy {...props} />)} />
            {/* CODE YOUR ROUTE HERE */}
            
            {/* 404 NOT FOUND */}
            <Route>
                <Container>
                    <Box display="flex">
                        Error 404
                    </Box>
                </Container>
            </Route>
        </Switch>
    )
}
