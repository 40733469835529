import { lazy, Suspense, useContext, useEffect } from "react"
import { VM } from "../../core/VM"
import { pslstyle } from "../../core/styling/si"
import { ToolContext } from "../../core/context/ToolContext";
import { validateAccount } from "../../core/validator/Validator";
import { rqx } from '../../core/request/API';
import { env, domain } from '../../core/Env';
import { useLocation, useHistory } from 'react-router-dom';

const device = VM();
const Auth = lazy(() => import(`.${device}/Auth`))

export const SI = ({ match }) => {
    const __SESSION = JSON.parse(localStorage.getItem('pm_tool_session'))
    const { si_state } = useContext(ToolContext)
    const { step, va, inputs, tpinputs } = si_state
    const q = new URLSearchParams(useLocation().search);
    const platform = match.params.platform
    const classes = pslstyle(platform)
    const history = useHistory()

    useEffect(() => {
        step.set(1)
        va.set('')
        inputs.set({account:{value: '', stat: false, msg: ''}, password:{value: '', stat: false, msg: '', toggle: false}})
        tpinputs.set({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const inputHandler = (e) => {
        const {name, value} = e.target
        tpinputs.set({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})
        inputs.set({...inputs.data, [name] : {...inputs.data[name], value: value, stat: false, msg: ''}})
        if (name==='account') {
            const v = validateAccount(e.target.value);
            if (v==='mobile_num' && e.target.value[0]==='0') {
                inputs.set({...inputs.data, [name] : {...inputs.data[name], value: e.target.value.substring(1), stat: false, msg: ''}})
            }
            va.set(v)
        }
    }

    const resetPassHandler = () => {
        const sainfo = {
            va: va.data,
            default: window.btoa(`unauth_${inputs.data.account.value}`),
            rrf: 'foundation',
            paid: null
        }

        history.push(`/${platform}/reset/password?${q.get('from')!==null?`from=${encodeURIComponent(q.get('from'))}&`:''}sainfo=${encodeURIComponent(JSON.stringify(sainfo))}`)
    }

    const enterHandler = (event) => { return event.key === 'Enter' ? next() : ''}
    const next = async () => {
        if (step.data!==10 && step.data!==20) {
            tpinputs.set({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})
            inputs.set({password: {...inputs.data.password, stat: false, msg: ''}, account: {...inputs.data.account, stat: false, msg: ''}})
            if (step.data===1) {
                step.set(10)
                if (inputs.data.account.value !== '') {
                    let attempt = await rqx.get(`<<tool>>/${env()}/${platform}/si/attempt/${va.data}`, {account: inputs.data.account.value, ref: 1, platform: platform})
                    if (attempt.msg === 'valid') {
                        if (platform==='psl') {
                            if (attempt.ut === 2) {
                                step.set(1)
                                inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Company account can't be used to POFPersonal"}})
                                return
                            }
                            if (attempt.ut === 5) {
                                step.set(1)
                                inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Clients are unable to use their accounts in this particular system."}})
                                return
                            }
                            if (attempt.at!==2) {
                                step.set(2)
                                inputs.set({...inputs.data, account: {...inputs.data.account, stat: false, msg: ''}})
                            } else {
                                let qsp = {
                                    va: va.data,
                                    account: window.btoa(`unauth_${inputs.data.account.value}`),
                                    step: 1,
                                    srf: 'default'
                                }
                                history.push(`/biz/switching/option1?${q.get('from')!==null?`from=${encodeURIComponent(q.get('from'))}&`:''}sainfo=${encodeURIComponent(JSON.stringify(qsp))}`)
                            }
                        } else {
                            if (attempt.ut === 5) {
                                step.set(1)
                                inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Clients are unable to use their accounts in this particular system."}})
                                return
                            }
                            if (attempt.at!==1) {
                                step.set(2)
                                inputs.set({...inputs.data, account: {...inputs.data.account, stat: false, msg: ''}})
                            } else {
                                let qsp = {
                                    va: va.data,
                                    account: window.btoa(`unauth_${inputs.data.account.value}`),
                                    step: 1,
                                    srf: 'default'
                                }
                                history.push(`/psl/switching/option1?${q.get('from')!==null?`from=${encodeURIComponent(q.get('from'))}&`:''}sainfo=${encodeURIComponent(JSON.stringify(qsp))}`)
                            }
                        }
                    } else {
                        step.set(1)
                        inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account does not exist"}})
                    }
                } else {
                    step.set(1)
                    inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account does not exist"}})
                }
            } else {
                step.set(20)
                if (inputs.data.password.value !== '') {
                    const pass = inputs.data.password.value.includes('#') ? encodeURIComponent(inputs.data.password.value): inputs.data.password.value
                    let attempt = await rqx.get(`<<tool>>/${env()}/${platform}/si/attempt/${va.data}`, {account: inputs.data.account.value, password: pass, ref: 2, platform: platform})
                    if (attempt.msg === 'valid') {
                        inputs.set({...inputs.data, password: {...inputs.data.password, stat: false, msg: ''}})
                        let url = platform==='psl'?domain('psl').url:domain('biz').url
                        if (q.get('from') !== null ) {
                            let subdir = `ainfo=${encodeURIComponent(JSON.stringify(attempt.ainfo))}`
                            if (__SESSION.tsa_key === 'TOOL_MARKET_PREVIEW_SSO_RQX') {
                                subdir += `&market_preview=${__SESSION.tid}&platform=${__SESSION.platform}`
                            } else if (__SESSION.tsa_key === 'MP_MARKET_CHECKOUT_SSO_RQX') {
                                subdir += `&platform=${__SESSION.platform}&tid=${__SESSION.tid}&pricing=${encodeURIComponent(__SESSION.pricing)}`
                            } else if (__SESSION.tsa_key === 'MP_DIRECT_CHECKOUT_RQX') {
                                subdir += `&platform=${__SESSION.platform}&tid=${__SESSION.tid}&pricing=${encodeURIComponent(__SESSION.pricing)}&ref=auth&subcb=${__SESSION.subcb}`
                            } else if (__SESSION.tsa_key === 'PFSHP_SSO_RQX') {
                                subdir += `&platform=${__SESSION.platform}&ref=auth&subcb=${__SESSION.subcb}`
                            } else if (__SESSION.tsa_key === 'PHRC_SSO_RQX') {
                                subdir += `&platform=${__SESSION.platform}&ref=auth&subcb=${__SESSION.subcb}`
                            } else if (__SESSION.tsa_key === 'PCHT_SSO_RQX') {
                                subdir += `&platform=${__SESSION.platform}`
                            } else if (__SESSION.tsa_key === 'PFJOB_SSO_RQX') {
                                subdir += `&platform=${__SESSION.platform}`
                            } else if (__SESSION.tsa_key === 'PFREFERRAL_SSO_RQX') {
                                subdir += `&platform=${__SESSION.platform}`
                            }
                                
                            url = `${__SESSION.cb}?${subdir}`
                        } else {
                            url = `${url}/sso/account/auth?ainfo=${encodeURI(JSON.stringify(attempt.ainfo))}&subcb=${q.get('subcb')}`
                        }
                        // console.log(url)
                        window.location.href = url
                    } else {
                        step.set(2)
                        inputs.set({...inputs.data, password: {...inputs.data.password, stat: true, msg: "Incorrect password."}})
                    }
                } else {
                    step.set(2)
                    inputs.set({...inputs.data, password: {...inputs.data.password, stat: true, msg: "Password is required"}})
                }
            }
        }
    }
    
    return (
        <Suspense fallback="">
            <Auth classes={classes} platform={platform} {...si_state} inputHandler={inputHandler} enterHandler={enterHandler} next={next} q={q} __SESSION={__SESSION} resetPassHandler={resetPassHandler} />
        </Suspense>
    )
}