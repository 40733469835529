import { lazy, Suspense, useContext, useEffect } from "react"
import { VM } from "../../core/VM"
import { pslstyle } from "../../core/styling/si"
import { ToolContext } from "../../core/context/ToolContext";
import { validateAccount } from "../../core/validator/Validator";
import { rqx } from '../../core/request/API';
import { env } from '../../core/Env';
import { useLocation } from 'react-router-dom';
import { Verification } from "./Verification";

const device = VM();
const Auth = lazy(() => import(`.${device}/Auth`))
const __SESSION = JSON.parse(localStorage.getItem('pm_tool_session'))

export const ResetPassword = ({ match }) => {
    const { reset_password_state } = useContext(ToolContext)
    const { step, va, inputs, tpinputs, otp } = reset_password_state
    const q = new URLSearchParams(useLocation().search);
    const platform = match.params.platform
    const classes = pslstyle(platform)

    useEffect(() => {
        const sainfo = JSON.parse(q.get('sainfo'))
        inputs.set({...inputs.data, account: {...inputs.data.account, value: window.atob(sainfo.default).split('_').pop()}})
        va.set(sainfo.va)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    const inputHandler = (e) => {
        const {name, value} = e.target
        tpinputs.set({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})
        inputs.set({...inputs.data, [name] : {...inputs.data[name], value: value, stat: false, msg: ''}})
        if (name==='account') {
            const v = validateAccount(e.target.value);
            if (v==='mobile_num' && e.target.value[0]==='0') {
                inputs.set({...inputs.data, [name] : {...inputs.data[name], value: e.target.value.substring(1), stat: false, msg: ''}})
            }
            va.set(v)
        }
    }

    const enterHandler = (event) => { return event.key === 'Enter' ? next() : ''}
    const next = async () => {
        if (step.data!==10 && step.data!==20) {
            const v = validateAccount(inputs.data.account.value)
            if (step.data===1) {
                step.set(10)
                if (inputs.data.account.value !== '') {
                    if (v!=='username') {
                        let attempt = await rqx.get(`<<tool>>/${env()}/${platform}/resetpass/attempt/${va.data}`, {account: inputs.data.account.value, ref: '1'})
                        // console.log(attempt);
                        if (attempt.msg === 'delivered') {
                            // redirect 
                            otp.set({...otp.data, inputted: '', code: attempt.vc})
                            step.set(2)
                            inputs.set({...inputs.data, account: {...inputs.data.account, stat: false, msg: ''}, aid: {...inputs.data.aid, value: attempt.aid}})
                        } else if (attempt.msg === '!exists') {
                            step.set(1)
                            inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account does not exist."}})
                        }
                    } else {
                        step.set(1)
                        inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account format is invalid."}})
                    }
                } else {
                    step.set(1)
                    inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account is required"}})
                }
            }
        }
    }
    
    return (
        <Suspense fallback="">
            {
                (step.data === 1 || step.data === 10) ? (
                    <Auth classes={classes} platform={platform} {...reset_password_state} inputHandler={inputHandler} enterHandler={enterHandler} next={next} q={q} __SESSION={__SESSION} />
                ) : (
                    <Verification classes={classes} platform={platform} {...reset_password_state} q={q} __SESSION={__SESSION} />
                )
            }
        </Suspense>
    )
}